import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';

import ContentWrapper from '../components/contentWrapper';
import Layout from '../components/layout';
import stylesheet from './blogTemplate.module.css';

const BlogTemplate = ({ data }) => {
  const post = data.markdownRemark;

  return (
    <Layout>
      <SEO title={post.frontmatter.title} />
      <ContentWrapper>
        <div className={stylesheet.postSpace}>
          <time datetime="" className={stylesheet.postDate}>{post.frontmatter.date}</time>
          <h1>{post.frontmatter.title}</h1>
          <article dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
      </ContentWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "DD MMMM YYYY")
        path
        title
      }
    }
  }
`;

export default BlogTemplate;
